<template>
  <div>
    <en-table-layout :tableData="pageData" :redundancy-height="0" @sort-change="handleSortChange">
      <template slot="table-columns">
        <el-table-column label="商品图片" prop="thumbnail" width="80" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt="" style="width: 300px">
              <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference" />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" show-overflow-tooltip :width="MixinIsFormEnterprise ? 100 : 150">
          <template slot-scope="scope">
            {{ scope.row.goods_name }}
          </template>
        </el-table-column>
        <el-table-column label="商品编号" width="130">
          <template slot-scope="scope">
            {{ scope.row.sn }}
          </template>
        </el-table-column>
        <el-table-column label="会员价" width="90" v-if="MixinIsFormEnterprise" sortable="custom" prop="price">
          <template slot-scope="scope">
            {{ scope.row.price | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column label="销售价" width="90" prop="revisePrice">
          <template slot-scope="scope">
            <span v-if="scope.row.goods_type === 'POINT'">
              {{ scope.row.revise_exchange_money | unitPrice('￥') }} +
              {{ scope.row.revise_exchange_point || 0 }}
              积分
            </span>
            <span v-else>
              {{ scope.row.revise_price | unitPrice('￥') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="利润率" width="90" v-if="MixinIsFormEnterprise" sortable="custom" prop="shop_profit">
          <template slot-scope="scope">
            {{ scope.row.shop_profit || 0 }}%
          </template>

          <template slot="header" slot-scope="{row}">
            <el-tooltip effect="dark" content="利润率 =（销售价 - 会员价）/ 销售价 * 100%" placement="top">
              <label class="cursor-pointer">利润率</label>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="$event => clickRemove(scope)">移除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </en-table-layout>
  </div>
</template>

<script>
import EnTableLayout from '../../../../../../../ui-components/TableLayout/src/main'
export default {
  name: 'ManagerSellerCouponTable',
  components: {
    EnTableLayout
  },
  props: {
    pageData: {
      type: Array,
      default: _ => []
    }
  },
  data () {
    return {
      MixinIsFormEnterprise: false
    };
  },

  mounted () {
    // 判断是否从企业端调过来
    if (sessionStorage.getItem('MixinIsFormEnterprise')) {
      this.MixinIsFormEnterprise = JSON.parse(sessionStorage.getItem('MixinIsFormEnterprise'))
    }
  },

  methods: {
    // 移除商品
    clickRemove (row) {
      this.$emit('clickRemove', row)
    },
    handleSortChange (data) {
      this.$emit('sort-change', data)
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-table--border {
  min-height: 458px;
  max-height: 458px;
}
</style>