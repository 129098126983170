import * as Base64 from 'js-base64';
import CryptoHmacSha1 from 'crypto-js/hmac-sha1';
import CryptoMd5 from 'crypto-js/md5';
import CryptoEncBase64 from 'crypto-js/enc-base64';
import axios from "axios";
// const CryptoJS = require('crypto-js')

export class OSS {
  access_key_id: string;
  access_key_secret: string;
  url: string;
  bucket: string;
  policy = '';
  signature = '';

  constructor(
    {
      access_key_id = '',
      access_key_secret = '',
      url = 'oss.aliyuncs.com',
      bucket = '',
    }
  ) {
    this.access_key_id = access_key_id;
    this.access_key_secret = access_key_secret;
    this.url = url;
    this.bucket = bucket;
    this.config();
  }

  config({max_file_size = 500 * 1024 * 1024} = {}) {
    this.policy = Base64.encode(JSON.stringify({
      expiration: `2030-01-01T12:00:00.000Z`,
      conditions: [
        ["content-length-range", 0, max_file_size]
      ]
    }));
    this.signature = CryptoHmacSha1(this.policy, this.access_key_secret).toString(CryptoEncBase64);
  }

  upload(file = new File([], ''), {
    dirname = '', // 指定目录
    onUploadProgress
  }) {
    const formData = new FormData();
    const key = `${dirname ? `${dirname}/` : ''}${CryptoMd5(file.name)}.${file.type.split('/')[1]}`;
    formData.append('name', file.name);
    formData.append('key', key);
    formData.append('policy', this.policy);
    formData.append('OSSAccessKeyId', this.access_key_id);
    formData.append('success_action_status', '200');
    formData.append('signature', this.signature);
    formData.append('file', file);
    return axios.post(`https://${this.bucket}.${this.url}`, formData, {onUploadProgress}).then(() => {
      return {data: `http://${this.bucket}.oss-accelerate.aliyuncs.com/${key}`};
    });
  }

  /**
   * 上传卡片封面图片
   */
  // uploadCardCoverImage(file, {
  //   base64 = false,
  //   onUploadProgress = () => {
  //   }
  // } = {}) {
  //   if (base64) file = new File([Base64.toUint8Array(base64)], 'file.png');
  //   return this.upload(file, {
  //     dirname: 'card-cover/',
  //     onUploadProgress
  //   });
  // }
}

export const $oss = new OSS({
  access_key_id: 'LTAI4FrvHJnybH5Yoyjg9TQ7',
  access_key_secret: 'ebza9JREtEV1SKgkBYez5NN8xarnq4',
  url: 'oss-cn-beijing.aliyuncs.com',
  bucket: 'sshlwap'
});
